<template>
  <div style="width: 100%; height: 100%" class="param-trend">
    <div class="chart">
      <Plotly
        class="plotly"
        :data="data"
        :layout="layout"
        :display-mode-bar="false"
      />
    </div>
  </div>
</template>

<script>
import { Plotly } from "vue-plotly";
import Widget from "@/common-lib/widgets/Widget";

export default {
  components: {
    Plotly,
  },
  mixins: [Widget],
  data() {
    return {
      receiveData: "",
      spec: {
        alarm: {
          highLimit: { low: -1, high: -3 },
          lowLimit: { high: -18, low: -16 },
        },
        warning: {
          highLimit: { high: -16, low: -14 },
          lowLimit: { low: 1, high: -1 },
        },
      },
      data: [
        {
          x: [
            "2022-02-01",
            "2022-02-02",
            "2022-02-03",
            "2022-02-04",
            "2022-02-05",
            "2022-02-06",
            "2022-02-07",
            "2022-02-08",
            "2022-02-09",
            "2022-02-10",
            "2022-02-11",
            "2022-02-12",
            "2022-02-13",
            "2022-02-14",
            "2022-02-15",
            "2022-02-16",
            "2022-02-17",
            "2022-02-18",
            "2022-02-19",
            "2022-02-20",
            "2022-02-21",
            "2022-02-22",
            "2022-02-23",
            "2022-02-24",
            "2022-02-25",
            "2022-02-26",
            "2022-02-27",
            "2022-02-28",
          ],
          y: [
            100, 100, 100, 100, 100, 100, 100, 100, 80, 80, 80, 80, 70, 70, 80,
            80, 80, 80, 80, 80, 80, 80, 80, 80, 80, 70, 65, 100,
          ],

          type: "scatter",
          mode: "lines+markers",
          name: "temperature",
          line: { shape: "hv" },
        },
        {
          x: [
            "2022-02-01",
            "2022-02-02",
            "2022-02-03",
            "2022-02-04",
            "2022-02-05",
            "2022-02-06",
            "2022-02-07",
            "2022-02-08",
            "2022-02-09",
            "2022-02-10",
            "2022-02-11",
            "2022-02-12",
            "2022-02-13",
            "2022-02-14",
            "2022-02-15",
            "2022-02-16",
            "2022-02-17",
            "2022-02-18",
            "2022-02-19",
            "2022-02-20",
            "2022-02-21",
            "2022-02-22",
            "2022-02-23",
            "2022-02-24",
            "2022-02-25",
            "2022-02-26",
            "2022-02-27",
            "2022-02-28",
          ],
          y: [
            1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
            1, 1, 1, 1, 1,
          ],
          marker: {
            color: [
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "blue",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
              "red",
            ],
          },
          type: "bar",
          name: "status",
          xaxis: "x2",
          yaxis: "y2",
        },
      ],
      layout: {
        autosize: true,
        margin: {
          l: 50,
          r: 15,
          b: 15,
          t: 15,
          pad: 0,
        },
        showlegend: false,

        // to highlight the timestamp we use shapes and create a rectangular

        // width: 500,
        // grid:{rows:2,columns:1, pattern:'independent'},
        yaxis: { domain: [0.25, 1], range: [0, 110] },
        xaxis2: { anchor: "x2", visible: false },
        yaxis2: {
          anchor: "y2",
          domain: [0, 0.125],
          visible: false,
          range: [0, 5],
        },
      },
    };
  },

  methods: {
    // 아래 함수만 구현 하면 됨
    // Override
    receiveConfig(config) {
      let date = this.$date(
        new Date(this.data[0].x[this.data[0].x.length - 1])
      );
      const sDate = date.add(1, "day").format("YYYY-MM-DD");
      this.data[0].x.push(sDate);
      this.data[1].x.push(sDate);
      let value = Math.random() * (120 - 80) + 80;

      let color = "red";
      if (value >= 100) {
        value = 100;
        color = "blue";
      }
      this.data[0].y.push(value);

      this.data[1].marker.color.push(color);

      this.data[0].x.splice(0, 1);
      this.data[1].x.splice(0, 1);
      this.data[0].y.splice(0, 1);
      this.data[1].marker.color.splice(0, 1);

      this.data = Object.assign([], this.data);
      this.layout = Object.assign({}, this.layout);
    },
    receiveSyncData(data) {
      console.log("receiveSyncData", data);
      this.receiveData = data;
    },
    changeData() {
      let data = { date: new Date(), message: "my sync data" };
      this.sendSyncData(data);
    },
  },
};
</script>

<style  lang="scss">
.param-trend {
  .chart {
    //height: calc(100% - 50px);
    height: 100%;
    width: 100%;
  }
  .plotly {
    width: 100%;
    height: 100%;
  }
}
</style>
